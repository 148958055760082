<template>
  <div id="books_info1">
    <div class="librarysss">
      <div class="libraryss" v-for="(item,index) in librarys" :key="index">
        <div ref="lib" class="title" :id="item.roomname?item.roomname.room_id:''">
          <p ref="title" @click="selected(item)" class="aqt_lang_mn titlefont">
            {{ item.libraryname ? item.libraryname.mn ? item.libraryname.mn : item.libraryname.zh : '' | U2M }} —
            <span
                v-if="item.roomname"
                class="library"
            >{{ item.roomname.name|U2M }}</span>
          </p>
        </div>
      </div>
    </div>
    <div style="display:flex">
      <div class="aqt_zhengzhuang" style="margin-left:15px;">
        <i class="iconfont iconshuming"></i>
        <p v-if="infosData" class="aqt_lang_mn contentfont">{{ $t('m.libraryname')|U2M }} :
          {{ infosData.library_name_lang|U2M }}</p>
      </div>
      <div class="aqt_zhengzhuang">
        <i class="iconfont iconchubanshe"></i>
        <p v-if="infosData" class="aqt_lang_mn contentfont">{{ $t('m.ylroom')|U2M }} : {{ infosData.name_lang|U2M }}</p>
      </div>
      <!-- 索书号 -->
      <div class="aqt_zhengzhuang" v-if="infosData.other&&infosData.other.length&&infosData.other[0].call_num">
        <i class="iconfont iconno"></i>
        <p class="aqt_lang_mn contentfont">
          {{ $t('m.suobookh')|U2M }}:
          <span v-for="(item,index) in infosData.other" :key="index">{{ item.call_num ? item.call_num : '' }}</span>
        </p>
      </div>
      <!-- 条形码 -->
      <div class="aqt_zhengzhuang" v-if="infosData.other&&infosData.other.length&&infosData.other[0].bar_code">
        <i class="iconfont iconno1"></i>
        <p class="aqt_lang_mn contentfont">
          {{ $t('m.txm')|U2M }}:
          <span v-for="(item,index) in infosData.other" :key="index">{{ item.bar_code ? item.bar_code : '' }}</span>
        </p>
      </div>
      <!--			&lt;!&ndash; Mac信息 &ndash;&gt;-->
      <!--			<div class="aqt_zhengzhuang" v-if="infosData.marc && infosData.marc!='0'">-->
      <!--				<i class="iconfont iconno2"></i>-->
      <!--				<p class="aqt_lang_mn contentfont">{{$t('m.macinfor')|U2M}}:{{infosData.marc|U2M}}</p>-->
      <!--			</div>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {},
  },
  data() {
    return {
      librarys: [],
      infosData: {
        publish_date: "",
        word_count: "",
        isbn: "",
        author: "",
        name_lang: "",
        press: "",
        content_lang: "",
      },
      data1: {
        //获取书籍数据
        book_id: "",
        member_id: JSON.parse(localStorage.getItem("userInfoMemberId"))
            ? JSON.parse(localStorage.getItem("userInfoMemberId")).member_id
            : "",
      },
      queryData: {
        book_id: "",
        library_id: [],
      },
      libraryinfo: [],
      type: "2", //电子书
      id: ''
    };
  },
  methods: {
    selected(item) {
      let _this = this;
      this.$refs.lib.forEach((item1, index) => {
        this.$refs.lib[index].classList.remove("active");
        console.log(item1.id);
        console.log(item.roomname.room_id);
        if (item1.id == item.roomname.room_id) {
          console.log(111);
          this.$refs.lib[index].classList.add("active");
        }
      });
      if (item) {
        this.infosData.library_name_lang = item.libraryname
            ? item.libraryname.mn
                ? item.libraryname.mn
                : item.libraryname.zh
            : "ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠦᠭᠡᠢ";
        this.infosData.name_lang = item.roomname.name_lang
            ? item.roomname.name_lang.mn
                ? item.roomname.name_lang.mn
                : item.libraryname.zh
            : "ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠦᠭᠡᠢ";
        this.infosData.other = item.other
        // this.infosData.marc = item.marc
        // this.infosData.bar_code = item.bar_code
      } else {
        this.infosData = {};
      }
    },
    /*****************************************************************
     *
     * 获取详情数据
     *
     *****************************************************************/
    infoData() {
      const _this = this;
      _this.showToastMask(true);
      console.log('this.data.libraryotherlist', this.data.libraryotherlist)

      this.librarys = _this.data.libraryotherlist;

      let datas = this.librarys[0];
      this.infosData.library_name_lang = datas.libraryname
          ? datas.libraryname.mn
              ? datas.libraryname.mn
              : datas.libraryname.zh
          : "ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠦᠭᠡᠢ";


      this.infosData.name_lang = datas.roomname
          ? datas.roomname.name_lang
              ? datas.roomname.name_lang.mn
                  ? datas.roomname.name_lang.mn
                  : datas.roomname.library_name_lang.zh
              : "ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠦᠭᠡᠢ"
          : "ᠣᠳᠣᠬᠠᠨ ᠳᠠᠭᠠᠨ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠦᠭᠡᠢ";


      this.infosData.other = datas.other

      // this.infosData.marc = datas.marc?datas.marc:''
      // this.infosData.bar_code = datas.other[0].bar_code

      this.$nextTick(() => {
        _this.$refs.lib[0].classList.add("active");
        for (let i = 0; i < _this.librarys.length; i++) {
          _this.$refs.lib[i].style.width =
              _this.$refs.title[i].offsetWidth + "px";
        }
      });
      _this.showToastMask(false);
      localStorage.setItem("youmuBook_intro", JSON.stringify(_this.data));


    },

  },
  created() {
    this.infoData();
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
#books_info1 {
  display: flex;
  //height: calc(100% - 24rem);
  // width: 100%;
  overflow: scroll !important;
  padding: 0 20px;

  .active {
    height: calc(100% - 3.4rem);

    p {
      color: #fff;

      span {
        color: #fff;
      }
    }

    background-color: var(--themeColor);
  }

  .librarysss {
    height: calc(100% - 3.4rem);
    display: flex;
  }

  .libraryss {
    height: calc(100% - 3.4rem);
    float: left;

    .title {
      padding-top: 10px;
      color: var(--themeColor);
      font-size: 1.8rem;
      border: 1px solid var(--themeColor);
      margin-left: 5px;
      margin-bottom: 10px;
      border-radius: 5px;
      line-height: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;

      // box-sizing: border-box;
      p {
        display: block;
        height: 70rem;
        min-width: 36px;
        padding: 0 10px;
      }

      .library {
        font-size: 1.4rem;
        padding: 5px;
        border-radius: 5px;
      }
    }
  }

  .aqt_zhengzhuang {
    height: calc(100% - 3.4rem);
    min-width: 3.5rem;
    color: #333;
    font-size: 1.2rem;
    margin: 0 0.3rem;
    // white-space: nowrap;
    // 	-o-text-overflow: ellipsis;
    // 	text-overflow: ellipsis;
    // 	overflow: hidden;
    i {
      color: var(--themeColor);
      display: block;
      margin-bottom: 0.5rem;
      font-size: 2rem;
    }

    img {
      width: 1.6rem;
      height: 1.6rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1.8rem;
      max-height: 90%;
      word-break: normal;
      color: #888888;

      span {
        display: block;
      }
    }
  }
}
</style>
